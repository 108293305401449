<script setup lang="ts">
  import { ref } from 'vue'

  const props = defineProps(['message'])
  defineEmits(['post-append'])

  // const message = ref('Multius!');
  const count = ref(0)
  const hello_who = ref(props.message)

  const changeMessage1 = () => {
    hello_who.value = 'McDonald\'s!';
  };
  const changeMessage2 = () => {
    hello_who.value = 'KFC!';
  };
  const name = ref("")


  function enter_name() {
    hello_who.value = name.value
    name.value = ''
  }
</script>

<template>
  <h1>Hello! {{ hello_who }}!</h1>

  <input
    v-model="name"
    @keyup.enter="enter_name"
    placeholder="What is your name?"
    autocomplete="new-password"
  />
    <p>Which would you prefer,
    <a @click="changeMessage1" target="_blank">McDonald's</a>
    or
    <a @click="changeMessage2" target="_blank">KFC</a>
    ?
  </p>

  <button type="button" @click="count++; $emit('post-append')">
    Click to post {{ count }}th blog
  </button>

</template>

<style scoped>
.read-the-docs {
  color: #888;
}
</style>
