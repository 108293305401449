<script setup>
  import { ref } from 'vue'
  defineProps(['id'])

</script>

<template>
  <h4>这是第{{ id }}篇博客的标题</h4>
  <p>这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
    这是第{{ id }}篇博客的正文
  </p>
</template>

<style scoped>
</style>
